import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import '../tailwind.css';
import './index.css';
import ProgramsList from './list';

function LittleCoder() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16">
        <div className="flex flex-col items-center w-screen bg-sky-300">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center w-10/12 md:w-8/12 m-2">
                <div className="col-span-1 md:col-span-1 m-2">
                    <img src={process.env.PUBLIC_URL + '/programs/LittleCoder.png'} alt="Little Coder" className='size-full' />
                </div>
                <div className="col-span-1 md:col-span-1 m-5">
                    <h1 className="text-black font-bold text-4xl">
                        Little Coder <br></br>(4-8 years old)
                    </h1>
                    <h2 className="text-black text-xl mb-4">
                        A fun and interactive way to introduce coding to young children.
                    </h2>
                    <Link to="/demo" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Book Free Class
                    </Link>
                </div>
            </div>
        </div>

        <div className="flex justify-center flex-wrap w-full md:w-8/12">
            <div className="w-full md:w-1/4 p-4 border-r-2 border-gray-200 md:sticky-toc">
                <h2 className="font-bold mb-4">Table of Contents</h2>
                <ul className="space-y-2">
                    <li><a href="#what-is-little-coder">What is Little Coder?</a></li>
                    <li><a href="#what-will-student-learn">What will student learn?</a></li>
                    <li><a href="#benefits">Benefits</a></li>
                </ul>
            </div>
            <div className="w-full md:w-3/4 p-4">
                <div id="what-is-little-coder" className="flex flex-col items-center w-full p-10 rounded-lg">
                    <h2 className="text-black font-bold text-4xl mb-6">
                        What is Little Coder?
                    </h2>
                    <p className="text-black text-2xl mb-4 p-6 rounded-lg">
                        Little Coder is a program designed for children aged 5-7 years old.
                        It is a fun and interactive way to introduce coding to young children.
                        The program is designed to help children develop their logical thinking and problem-solving skills.
                        Children will learn the basics of coding through fun and engaging activities.
                        They will learn how to create simple programs using visual programming languages.
                        The program is designed to be hands-on and interactive, so children can learn by doing.
                    </p>
                </div>
                <div id="what-will-student-learn" className="flex flex-col items-center w-full p-10 mt-10 rounded-lg">
                    <h2 className="text-black font-bold text-4xl mb-6">
                        What will student learn?
                    </h2>
                    <p className="text-black text-2xl mb-4 p-6 rounded-lg">
                        Students will learn the basics of coding, including:
                        <ul className="list-disc list-inside">
                            <li>Sequencing</li>
                            <li>Loops</li>
                            <li>Conditional statements</li>
                            <li>Events</li>
                            <li>Variables</li>
                            <li>Functions</li>
                        </ul>
                    </p>
                </div>
                <div id="benefits" className="flex flex-col items-center w-full p-10 mt-10 rounded-lg">
                    <h2 className="text-black font-bold text-4xl mb-6">
                        Benefits of Little Coder
                    </h2>
                    <p className="text-black text-2xl mb-4 p-6 rounded-lg">
                        <ul className="list-disc list-inside">
                            <li>One-on-one classes</li>
                            <li>Certificate of completion</li>
                            <li>Flexible schedules</li>
                            <li>Experienced instructors</li>
                            <li>Interactive learning environment</li>
                            <li>Hands-on coding experience</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
            
    </div>
  );
}

export default LittleCoder;