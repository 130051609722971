import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const FloatingButton = () => {
  return (
    <a href="https://wa.me/+6285183193700" target="_blank" rel="noopener noreferrer" style={{
      position: 'fixed',
      width: '60px',
      height: '60px',
      bottom: '40px',
      right: '40px',
      backgroundColor: '#25d366',
      color: '#FFF',
      borderRadius: '50px',
      textAlign: 'center',
      fontSize: '30px',
      boxShadow: '2px 2px 3px #999',
      zIndex: '100',
    }}>
      <FontAwesomeIcon icon={faWhatsapp} style={{marginTop: '15px'}} />
    </a>
  );
};

export default FloatingButton;