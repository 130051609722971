import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './tailwind.css';
import Faq from './faq';
import ProgramsList from './programs/list';

const buttonClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";
const whyUsCardClasses = "col-span-1 my-10 md:m-5 bg-white rounded-3xl shadow-lg hover:bg-gray-100 transition-colors duration-200 ease-in-out w-64 h-128 overflow-auto items-center text-center flex flex-col justify-center";
const whyUsTextClasses = "text-[#333] text-xl m-4";

const whyUsCards = [
    {
        image: "diverse.png",
        brief: "For every age",
        text: "We believe that every child should have the opportunity to learn how to code."
    },
    {
        image: "playing.png",
        brief: "Fun and engaging",
        text: "We provide a fun and engaging environment for students to learn coding."
    },
    {
        image: "wide.png",
        brief: "Wide range of courses",
        text: "We offer a diverse catalog of courses, from coding foundations to advanced topics."
    },
    {
        image: "teacher.png",
        brief: "Experienced instructors",
        text: "We have experienced instructors who are passionate about teaching coding."
    },
    {
        image: "calendar.png",
        brief: "Flexible schedules",
        text: "We offer flexible schedules to accommodate students' busy lives."
    },
    {
        image: "studentcenter.png",
        brief: "Student centered approach",
        text: "We take a student-centered approach to teaching coding, focusing on individual needs."
    }
];

function Homepage() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16">
        {/* ---- Banner ----- */}
        <div className="flex justify-center items-center w-screen bg-gradient-to-r from-green-400 to-blue-500">
            <div className="grid grid-cols-1 md:grid-cols-5 items-center w-10/12 m-2">
                <div className="col-span-1 md:col-span-3 my-20 md:m-20">
                <h1 className="text-white font-bold text-4xl">
                    Unlock Adventures
                    <br></br>
                    with Code!
                </h1>
                <h2 className="text-white text-2xl mb-4">
                    Invites students to explore the exciting world of coding as if it were a grand adventure.
                </h2>

                <Link to="/demo" className={`${buttonClasses} bg-white text-white hover:bg-gray-200 transition duration-200 ease-in-out`}>
                    Free First Class
                </Link>
                <Link to="/programs" className="ms-4 text-white underline hover:text-blue-200 transition duration-200 ease-in-out">
                    See our courses
                </Link>
                </div>

                <div className="hidden md:block md:col-span-2">
                <img src={process.env.PUBLIC_URL + '/homepage/Designer2.png'} alt="Coding" className='size-full' />
                </div>
            </div>
            </div>

        {/* ---- Why Us ----- */}
        <div className="flex justify-center items-center w-screen">
            <div className="grid grid-cols-1 md:grid-cols-3 items-center w-full md:w-10/12 m-2 justify-items-center">
                <div className="col-span-1 md:col-span-3 items-center text-center">
                    <h1 className="text-[#333] font-bold text-4xl m-10">
                        Why Us?
                    </h1>
                </div>

                {whyUsCards.map((card, index) => (
                    <div key={index} className={`${whyUsCardClasses}`}>
                        <img src={process.env.PUBLIC_URL + '/homepage/' + card.image} alt={card.brief} className='size-11/12' />
                        <h2 className={`${whyUsTextClasses} font-semibold`}>
                            {card.brief}
                        </h2>
                        <p className={`${whyUsTextClasses}`}>
                            {card.text}
                        </p>
                    </div>
                ))}
            </div>
        </div>
        
        {/* ---- Programs ----- */}
        <ProgramsList />

        {/* ---- FAQ ----- */}
        <Faq />

    </div>
  );
}

export default Homepage;