import React from 'react';
import '../tailwind.css';
import './index.css';
import ContactButton from './contactbutton';

function Contact() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16">
        <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row justify-center items-start w-full">
            {/* Map Section */}
            <div className="w-full md:w-1/2 p-4">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d989.4549924902033!2d112.77379746960162!3d-7.2613194995465875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f9861f2a5bef%3A0x458e4492087f917!2sJl.%20Kalijudan%20No.20%2C%20Kalijudan%2C%20Kec%3Amulyorejo%2C%20Surabaya%2C%20Jawa%20Timur%2061104!5e0!3m2!1sen!2sid!4v1724904742912!5m2!1sen!2sid"
                    width="100%"
                    height="400"
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Maps"
                    className="border-0"
                ></iframe>
            </div>
            {/* Information Section */}
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
                <h2 className="text-gray-800 font-bold text-4xl mb-4">
                    Contact Us
                </h2>
                <p className="text-gray-600 mb-4">
                    If you have any questions or need further information, feel free to contact us. We are here to help you!
                </p>

                <form className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Send Message
                        </button>
                    </div>
                </form>

                <ContactButton />
            </div>
        </div>
    </div>
  );
}

export default Contact;