import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../tailwind.css';

const buttonClasses = "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";

const programs = [
    {
        title: "Little Coder (Ages 4-8)",
        description: "An introductory coding course for young children.",
        image: "LittleCoder.jpg",
        link: "/programs/little-coder"
    },
    {
        title: "Junior Coder (Ages 9-13)",
        description: "A coding course for children who have some coding experience.",
        image: "JuniorCoder.jpg",
        link: "/programs/junior-coder"
    },
    {
        title: "Senior Coder (Ages 14-18)",
        description: "An advanced coding course for older children and teenagers.",
        image: "SeniorCoder.jpg",
        link: "/programs/senior-coder"
    }
];

function ProgramsList() {
  return (
    <div className="bg-[#FAFAFA]">
        {/* ---- Programs ----- */}
        <div className="flex justify-center items-center w-screen">
            <div className="grid grid-cols-1 items-center w-10/12 m-2">
                <div className="col-span-1 items-center text-center">
                    <h1 className="text-[#333] font-bold text-4xl m-10">
                        Our Programs
                    </h1>
                </div>

                {programs.map((program, index) => (
                    <div key={index} className="flex flex-col md:flex-row mb-10 border-2 border-gray-200 shadow-md">
                        <div className={`w-full md:w-1/2 items-center flex justify-center ${index % 2 === 0 ? 'md:order-first' : 'md:order-last'}`}>
                            <img src={process.env.PUBLIC_URL + '/homepage/' + program.image} alt={program.title} className='w-full md:w-2/3' />
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-4">
                            <h2 className="text-[#333] text-2xl m-4 font-semibold">
                                {program.title}
                            </h2>
                            <p className="text-[#333] text-lg m-4">
                                {program.description}
                            </p>
                            <Link to={program.link} className={`${buttonClasses}`}>
                                Learn More
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    </div>
  );
}

export default ProgramsList;