import React from 'react';
import '../tailwind.css';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

function ContactButton() {
  return (
    <div className="flex justify-center md:justify-start mt-8 space-x-4 mb-4">
        <a
            href="https://wa.me/+6285183193700"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
        >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>

        <a
            href="https://www.facebook.com/profile.php?id=61563928552575"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>

        <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-pink-500 hover:bg-pink-600 focus:ring-4 focus:ring-pink-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-pink-600 dark:hover:bg-pink-700 focus:outline-none dark:focus:ring-pink-800"
        >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>

        <a
            href="mailto:support@ailab.pp.ua"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
        >
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </a>
    </div>
  );
}

export default ContactButton;