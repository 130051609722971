import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './tailwind.css';
import './faq.css';

const questionClasses = "text-gray-700 text-xl mt-4 p-4 bg-white rounded shadow-md w-full md:w-10/12 font-semibold hover:bg-gray-200 transition duration-200 ease-in-out";
const answerClasses = "text-gray-700 text-lg p-4 bg-gray-100 rounded shadow-md w-full md:w-10/12";
const answerAnimation = "animate-fade-in-down";

const faq = [
    {
        question: "What is AiLab?",
        answer: "AiLab is an online platform that offers coding courses for children and teenagers. We provide a fun and engaging environment for students to learn coding."
    },
    {
        question: "Who can enroll in AiLab courses?",
        answer: "Our courses are designed for students of all ages. We offer courses for children as young as 4 years old, as well as courses for teenagers."
    },
    {
        question: "What courses does AiLab offer?",
        answer: "We offer a wide range of courses, from coding foundations to advanced topics. Our courses cover various programming languages and concepts."
    },
    {
        question: "How much do AiLab courses cost?",
        answer: "To inquire about the cost of our courses, please contact us directly or try our free trial course. We offer discounts for multiple course enrollments."
    },
    {
        question: "Who are the instructors at AiLab?",
        answer: "Our instructors are experienced professionals who are passionate about teaching coding. They have a strong background in computer science and education."
    },
    {
        question: "How do I enroll in an AiLab course?",
        answer: "You can enroll in an AiLab course by visiting our website and selecting the course you are interested in. You can then register for the course and pay the enrollment fee."
    },
    {
        question: "What is the duration of AiLab courses?",
        answer: "Each session of an AiLab course is typically 1 hour long. The duration of the course depends on the number of sessions and the course content."
    }
];

function Faq() {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
  
    const toggleQuestion = (index) => {
      setSelectedQuestions((prevSelectedQuestions) =>
        prevSelectedQuestions.includes(index)
          ? prevSelectedQuestions.filter((i) => i !== index)
          : [...prevSelectedQuestions, index]
      );
    };
  
    return (
      <div className="bg-[#FAFAFA]">
        <div className="flex justify-center items-center w-full">
          <div className="w-full md:w-10/12 m-2">
            <div className="text-center">
              <h1 className="text-[#333] font-bold text-4xl m-10">
                Frequently Asked Questions
              </h1>
              <p className="text-[#333] text-lg m-4">
                Here are some common questions about AiLab and our coding courses. If you have any other questions, feel free to contact us.
              </p>
            </div>
  
            {faq.map((item, index) => (
              <div key={index} className="flex flex-col items-center text-center justify-center">
                <button className={questionClasses} onClick={() => toggleQuestion(index)}>
                  {item.question}
                </button>
                <CSSTransition
                  in={selectedQuestions.includes(index)}
                  timeout={300}
                  classNames="transition"
                  unmountOnExit
                >
                  <p className={answerClasses}>{item.answer}</p>
                </CSSTransition>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  
  export default Faq;