import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './navbar';
import Homepage from './homepage';
import Programs from './programs';
import LittleCoder from './programs/littlecoder';

import AboutUs from './about';
import Contact from './about/contact';
import Career from './about/career';
import FaqPage from './about/faq';

import LoginPage from './user/login'; // Import the LoginPage component
import Questionnaire from './user/demo'; // Import the Questionnaire component

import FloatingButton from './FloatingButton'; // Import the FloatingButton component

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Programs route */}
        <Route path="/" element={<Homepage />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/programs/little-coder" element={<LittleCoder />} />

        {/* About route*/}
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/faq" element={<FaqPage />} />

        {/* User login route */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/demo" element={<Questionnaire />} />
      </Routes>
      <FloatingButton /> {/* Add the FloatingButton component here */}
      <Footer />
    </Router>
  );
}

export default App;