import React from 'react';
import '../tailwind.css';
import './index.css';
import ContactButton from './contactbutton';
function AboutUs() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16">
        <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row justify-center items-start w-full">
            {/* Map Section */}
            <div className="w-full md:w-1/2 p-4">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d989.4549924902033!2d112.77379746960162!3d-7.2613194995465875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f9861f2a5bef%3A0x458e4492087f917!2sJl.%20Kalijudan%20No.20%2C%20Kalijudan%2C%20Kec%3Amulyorejo%2C%20Surabaya%2C%20Jawa%20Timur%2061104!5e0!3m2!1sen!2sid!4v1724904742912!5m2!1sen!2sid"
                    width="100%"
                    height="400"
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Maps"
                    className="border-0"
                ></iframe>
            </div>
            {/* Information Section */}
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
                <h2 className="text-gray-800 font-bold text-4xl mb-4">
                    About Us
                </h2>
                <p className="text-gray-600 mb-4">
                    Welcome to our coding school! We are dedicated to teaching coding in a fun and engaging way. Our programs are designed for all ages and skill levels, ensuring that everyone can learn to code.
                </p>
                <p className="text-gray-600 mb-4">
                    Our mission is to make coding accessible to everyone. We believe that coding is an essential skill in today's digital world, and we strive to provide the best learning experience possible.
                </p>
                <p className="text-gray-600 mb-4">
                    Join us and start your coding journey today!
                </p>
                <p className="text-gray-600">
                    WhatsApp : +6285183193700
                </p>
                <p className="text-gray-600">
                    Email : support@ailab.pp.ua
                </p>

                <ContactButton />
            </div>
        </div>
    </div>
  );
}

export default AboutUs;