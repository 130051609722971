import React from 'react';
import '../tailwind.css';
import './index.css';
import Faq from '../faq';
import ContactButton from './contactbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

function FaqPage() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16">
        <div className="max-w-screen-lg mx-auto flex flex-col justify-center items-start w-full px-4 md:px-0">
            {/* Information Section */}
            <Faq />

            <div className="mt-4 w-full">
                <p className="text-gray-600 mb-4">
                    If you have any questions or need further information, feel free to contact us. We are here to help you!
                </p>

                <ContactButton />
            </div>
        </div>
    </div>
  );
}

export default FaqPage;