import React from 'react';
import { Link } from 'react-router-dom';
import './tailwind.css';

function Footer() {
  return (
    <footer className="bg-[#111827]">
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-3 max-w-10xl mx-auto m-8">
          <div className="text-gray-500 dark:text-gray-400">
            <img src={process.env.PUBLIC_URL + '/logo500.png'} alt="AiLab" className='size-40' />
            <p>Our mission is to provide the best learning experience for all students.</p>
          </div>
          <div className="text-gray-500 dark:text-gray-400">
            <h3 className='font-bold'>Company Profile</h3>
            <ul>

            </ul>
          </div>
          <div className="text-gray-500 dark:text-gray-400">
            <h3 className='font-bold'>Contact Us</h3>
            <ul>
              <li><a href="https://wa.me/+6285183193700">+6285183193700</a></li>
              <li>support@ailab.pp.ua</li>
              <li><a href="https://www.facebook.com/profile.php?id=61563928552575">Facebook</a></li>
              <li><a href="https://www.twitter.com">Twitter</a></li>
              <li><a href="https://www.instagram.com">Instagram</a></li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col justify-center items-center text-center text-gray-500 dark:text-gray-400 space-y-2">
        <hr class="w-full h-0.5 mx-auto bg-gray-100 border-0 rounded dark:bg-gray-700"></hr>
        <p>© 2024 AiLab. All rights reserved.</p>

        <Link to="/terms" className="text-blue-500 dark:text-blue-400">Terms of Service</Link>
        <br />
      </div>
    </footer>
  );
}

export default Footer;