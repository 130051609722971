import React from 'react';
import '../tailwind.css';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

function Career() {
  return (
    <div className="bg-[#FAFAFA] pt-10 md:pt-16">
        <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row justify-center items-start w-full">
            {/* Information Section */}
            <div className="w-full md:w-1/2 p-4 text-center md:text-left">
                <h2 className="text-gray-800 font-bold text-4xl mb-4">
                    Join Our Team
                </h2>
                <p className="text-gray-600 mb-4">
                    We are always looking for talented and passionate individuals to join our team.
                    If you are interested in working with us, please send us your resume and cover letter.
                </p>

                <p className="text-gray-600 mb-4">
                    Email : carrer@ailab.pp.ua
                </p>

                <form className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="position" className="block text-sm font-medium text-gray-700">Position</label>
                        <select
                            id="position"
                            name="position"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        >
                            <option value="">Select a position</option>
                            <option value="Teacher">Teacher</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Customer support">Customer support</option>
                            <option value="Tech team">Tech team</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label htmlFor="attachment" className="block text-sm font-medium text-gray-700">Attachment</label>
                        <input
                            type="file"
                            id="attachment"
                            name="attachment"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Send Message
                        </button>
                    </div>
                </form>

                <div className="flex justify-center md:justify-start mt-8 space-x-4">
                    <a
                        href="https://wa.me/+6285183193700"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800"
                    >
                        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                    </a>

                    <a
                        href="https://www.facebook.com/profile.php?id=61563928552575"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>

                    <a
                        href="https://www.instagram.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white bg-pink-500 hover:bg-pink-600 focus:ring-4 focus:ring-pink-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-pink-600 dark:hover:bg-pink-700 focus:outline-none dark:focus:ring-pink-800"
                    >
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>

                    <a
                        href="mailto:carrer@ailab.pp.ua"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-semibold rounded-full text-sm px-6 py-3 transition duration-200 ease-in-out transform hover:scale-110 active:scale-95 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                    >
                        <FontAwesomeIcon icon={faEnvelope} size="2x" />
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Career;